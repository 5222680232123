<template>
  <div v-if="pedido.ficha" id="pedidoImprimir">
    <h4 class="text-center mb-5">{{ $t('Pedido') }} {{ pedido.ficha.id }}</h4>
    <hr />
    <b>{{ $t('Data_Pedido') }}: </b> {{ pedido.ficha.data_lancamento | converteData }} <br />
    <b>{{ $t('Paciente') }}: </b> {{ pedido.ficha.paciente }} <br />
    <b v-if="pedido.ficha.os_pedido"> IP: {{ pedido.ficha.os_pedido.ip }} <br /> </b>
    <b>{{ $t('Materiais_Enviados') }}: </b>
    <span v-for="(m, index) in pedido.ficha.materiais" :key="index">
      {{ m.nome }} ({{ m.qtd }})
    </span>
    <br />

    <hr>
    <table>
      <thead>
        <th width="80">{{ $t('QTD') }}</th>
        <th width="180">{{ $t('SERVICO_PRODUTO') }}</th>
        <th width="180">{{ $t('NUM_DENTE') }}</th>
        <th width="180">{{ $t('COR_DENTE') }}</th>
        <th width="250">{{ $t('PRAZO') }}</th>
        <th width="270">{{ $t('VALOR_UNITARIO') }}</th>
        <th width="200">{{ $t('DESCONTO') }}</th>
        <th width="200" style="text-transform: uppercase" v-if="getPais !== 'BR'">{{ $t('Imposto') }}</th>
        <th width="200">{{ $t('SUBTOTAL') }}</th>
      </thead>
      <tbody v-for="(item, index) in pedido.ficha.items" :key="index">
        <tr>
          <td>{{ item.quantidade }}</td>
          <td v-if="item.servico_id">
            {{ pedido.servicos[item.servico_id][0].servico_nome }}
          </td>
          <td v-else-if="item.produto_id">
            {{ pedido.produtos[item.produto_id][0].nome }}
          </td>
          <td v-else>{{ $t('Custo_Entrega') }}</td>
          <td>{{ item.num_dente }}</td>
          <td>{{ item.cor_dente }}</td>
          <td>{{ item.data_prazo_lab | converteData }}</td>
          <td>{{ item.valor | formataMoeda }}</td>
          <td>{{ item.desconto | formataMoeda }}</td>
          <td v-if="getPais !== 'BR' "> % {{ item.iva }} </td>
          <td>{{ item.valor_total | formataMoeda }}</td>
        </tr>

        <tr>
          <td :colspan="colspan"> <strong>{{ $t('Observacao') }}: </strong> {{ item.observacao_item }}</td>
        </tr>

      <tr>
        <td :colspan="colspan" style="border-bottom: #ccc 1px solid; height: 15px"></td>
      </tr>

      </tbody>
    </table>
    <h4 class="float-right mt-2">{{ $t('Total') }} {{ $t('R$') }} {{ valorTotal | formataMoeda }}</h4>
    
   
    <div style="text-align: center;margin-top: 0;" v-if="pedido.ficha.os_pedido.img_assinatura">
      <img
        :src="'https://smartprotese.s3.sa-east-1.amazonaws.com/smartprotese/assinaturas/'+ pedido.ficha.os_pedido.img_assinatura"
        alt="Assinatura"
        style="width: 300px; height: auto;margin-left: 175px">
      <p  style="margin-top: -30px; font-size: 12px;">_________________________________________________________</p>
      <p style="margin-top: 0; font-size: 12px;"><strong> {{ pedido.ficha.cliente_nome}} |  {{ $t('CRO') }}: {{ pedido.ficha.os_pedido.cro }} </strong></p>
    
    </div>
 
  </div>
</template>

<script>

import formataMoeda from "@/mixins/formataMoeda";
import formataData from "@/mixins/formataData";
import {mapGetters} from "vuex";

export default {

  mixins: [formataMoeda, formataData],

  name: "ImprimirRelatorio",
  props: ["pedido"],

  data() {
    return {
      valorTotal: 0
    };
  },

  watch: {
    pedido(pedido) {
      if (pedido) {
        this.caculaTotal()
      }
    },

  },

  computed: {
    ...mapGetters('laboratorios', ['getPais']),
    colspan() {
      return this.getPais !== 'BR' ? 9 : 8
    }
  },
  methods: {
    caculaTotal() {
      this.valorTotal = this.pedido.ficha.items.reduce((total, element) => total + Number(element.valor_total), 0)
    }
  },
  
};
</script>

<style scoped>
</style>
