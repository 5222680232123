<template>
  <div>
    <b-row>
      <b-col>
        <b-button variant="gradient-primary" class="float-right mb-1 ml-1" @click="imprimirRelatorio">
          <feather-icon icon="PrinterIcon"></feather-icon>
          {{ $t('Imprimir')}}
        </b-button>
        <b-button variant="gradient-success" class="float-right mb-1" :to="{ name: 'pedidos_novo'}">
          <feather-icon icon="PlusIcon"></feather-icon>
          {{ $t('Pedidos') }}
        </b-button>
      </b-col>
    </b-row>

    <b-card>
      <b-row>
        <!-- SITUAÇÃO -->
        <b-col
          v-b-popover.hover.top=" $t('Ao_clicar_escolhe_a_situacao_atual_da_Os_como_Em_produção_em_prova_entregue_cancelado_etc')"
          class="my-1" md="3">
          <label label-for="situacao">{{ $t('Situacao') }}</label>
          <v-select id="situacao" v-model="filtros.situacao" multiple class="select-size-sm" label="value"
            :reduce="situacao => situacao.value" :options="situacaoOptionsTranslated" />
        </b-col>
        <b-col class="my-1">
          <b-form-group :label=" $t('Periodo')" label-size="sm" label-for="sortBySelect" class="mb-0">
            <!-- DATA -->
            <b-input-group size="sm">
              <b-form-select id="tipo" v-model="filtros.tipo" :options="tipoDatas" text-field="nome" value-field="value"
                class="w-50" @change="buscaEntradas" />
              <b-form-select id="mes" v-model="filtros.mes" :options="meses" text-field="nome" value-field="value"
                class="w-25" @change="buscaEntradas">
                <template v-slot:first>
                  <option value="">
                    {{ $t('Todos') }}
                  </option>
                </template>
                <template #option="{ value, text }">
                  {{ text | traduzOpcoesDatas }}
                </template>
              </b-form-select>
              <b-form-select v-model="filtros.ano" size="sm" class="w-25" :options="anos" text-field="nome"
                value-field="value" @change="buscaEntradas" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col class="my-1" md="2">
          <label label-for="cliente_lista">{{ $t('Dentista') }}</label>
          <v-select v-model="filtros.conveniado_id" class="select-size-sm" label="conveniado_nome"
            :reduce="conveniado => conveniado.id" :options="filteredConveniados"
            :clearable="podeLimparSelectConveniados">
            <div slot="no-options">
              {{ $t('Dentista_nao_encontrado') }}
            </div>
          </v-select>
        </b-col>
        <b-col class="my-1" md="3">
          <label label-for="busca">{{ $t('Busca') }}</label>
          <b-form-group id="busca" label-size="sm" label-for="filterInput" class="mb-0">
            <b-input-group size="sm">
              <b-form-input id="filterInput" v-model="filter" debounce="200" type="search"
                :placeholder=" $t('os_servico_e_paciente')" />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  {{ $t('Limpar') }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- TABELA -->
        <b-col cols="12">
          <b-table small striped hover responsive :items="itensFiltrados" :fields="fields" :filter="filter"
            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
            :filter-included-fields="filterOn" @sort-changed="sortChanged">

            <template #cell(num_os)="data">
              <b-badge v-if="data.item.num_os" variant="light-secondary">
                {{ data.item.num_os }}
              </b-badge>
            </template>
            <template #cell(data_lancamento)="data">
              {{ data.item.data_lancamento | converteData }}
            </template>
            <template #cell(data_prazo)="data">
              {{ data.item.data_prazo }}
            </template>
            <template #cell(data_entregue)="data">
              {{ data.item.data_entregue | converteDataHora() }}
            </template>
            <template #cell(servico_nome)="data">
              <span>{{ data.item.servico_nome }}
                <b-badge v-if="data.item.repeticao" variant="light-warning"> R</b-badge>
                <b-badge v-if="data.item.urgente" variant="light-danger"> U</b-badge>
                <b-badge v-if="data.item.prestador_id_pedido" variant="light-primary"> T</b-badge>
              </span>
            </template>
            <template #cell(etapa)="data">
              <b-badge v-if="data.item.etapa" :variant="data.item.cor_etapa" @click="editarEtapa(data)">
                {{ data.item.etapa }}
              </b-badge>
            </template>

            <template #cell(situacao)="data">
              <b-badge :variant="situacaoCor[0][data.item.situacao]">
                {{ data.item.situacao | traduzSituacao }}
              </b-badge>
              <b-button style="margin-left: 1px" class="btn-icon cursor-pointer" variant="outline-warning"
                v-if="data.item.botaoEnviarPedido" @click="enviarPedido(data.item)"
                :disabled="data.item.submit ? true : false" size="sm">
                <b-spinner small v-if="data.item.submit"></b-spinner> {{ $t('enviar') }}
              </b-button>
            </template>

            <!-- MOSTRA DETALHES DO ITEM-->
            <template #row-details="row">
              <b-card>
                <b-row>
                  <b-col md="4">
                    <strong>{{ $t('Os_Externa') }} : </strong>{{ row.item.os_externa }}
                  </b-col>
                  <b-col md="4">
                    <strong>{{ $t('Numero_do_Dente') }} : </strong>{{ row.item.num_dente }}
                  </b-col>

                  <b-col md="4">
                    <strong>{{ $t('Cor_do_Dente') }} : </strong>{{ row.item.cor_dente }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <strong> {{ $t('Prazo') }} : </strong> {{ row.item.data_prazo }}
                  </b-col>
                  <b-col md="4">
                    <strong> {{ $t('Data_Finalizado/Entregue') }} : </strong> {{ row.item.data_entregue |
                    converteDataHora() }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <strong>{{ $t('Valor_Unitario') }} : </strong> {{ row.item.valor | formataMoeda }}
                  </b-col>
                  <b-col md="4">
                    <strong> {{ $t('Desconto') }} : </strong> {{ row.item.desconto |
                    formataDesconto(row.item.tipo_desconto) }}
                  </b-col>
                  <b-col md="4">
                    <strong> Total : </strong> {{ row.item.valor_total | formataMoeda() }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <strong>{{ $t('Material_enviado_p/_Laboratorio') }} : </strong> <span
                      v-for="material in JSON.parse(row.item.materiais)">( {{ material.qtd }} ){{ material.nome }} -
                    </span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <strong>{{ $t('Observacao_Pedido') }} : </strong>
                    {{ row.item.observacao_item }}
                  </b-col>
                </b-row>

              </b-card>
              <hr>



              <b-row class="my-1">
                <b-col cols="6">
                  <!-- Botão que controla a exibição dos produtos -->
                  <b-button v-b-toggle="`collapse-${row.item.id}`" size="sm" variant="outline-success"
                    :disabled="!produtos[row.item.id]">
                    {{ $t('Ver_Produtos') }}
                  </b-button>

                  <!-- Área que expande ao clicar no botão -->
                  <b-collapse :id="`collapse-${row.item.id}`">
                    <div v-if="produtos[row.item.id]">
                      <table class="table table-bordered mt-1">
                        <thead>
                          <tr>
                            <th>{{ $t('Produto') }}</th>
                            <th>{{ $t('Quantidade') }}</th>
                            <th>{{ $t('Valor_Total') }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="produto in produtos[row.item.id]" :key="produto.id">
                            <td>{{ produto.nome }}</td>
                            <td>{{ produto.quantidade }}</td>
                            <td>{{$t('R$')}} {{ produto.valor_total | formataMoeda }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div v-else>
                      <p>{{ $t('Nenhum_Produto_Encontrado') }}</p>
                    </div>
                  </b-collapse>
                </b-col>
              </b-row>

              <hr>
              <b-card>
                <b-row align-h="center">
                  <h4 class="text-primary text-center">
                    <strong>{{ $t('Galeria_de_Arquivos/Imagens') }}</strong>
                  </h4>
                </b-row>

                <b-row>
                  <b-col>
                    <b-button class="float-right" variant="gradient-primary" @click="abreModalAddArquivos(row.item.id)">
                      <feather-icon icon="PlusIcon"></feather-icon> {{ $t('Adicionar_Arquivos') }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-container class="p-1 mt-1" v-if="imagens[row.item.id]">
                    <b-row>
                      <template v-for="(imagem, index) in imagens[row.item.id]">
                        <b-col class="col-auto" v-if="Number(imagem.tipo) === 1">
                          <b-button v-if="Number(imagem.odontodocs) === 1" size="sm" variant="flat-danger"
                            class="btn-icon float-right" @click="excluirImagem(index, imagem.id, row.item.id)">
                            <feather-icon icon="Trash2Icon" class="text-danger" />
                          </b-button>
                          <b-img thumbnail width="150" fluid
                            :src="imagem.url ? `https://smartprotese.s3.sa-east-1.amazonaws.com/smartprotese/${imagem.url}` : '' "
                            :alt="imagem.nome ? imagem.nome : '' " class="cursor-pointer"
                            @click="abreModalImagens(imagens[row.item.id], index)" />

                        </b-col>
                        <b-col class="col-auto" v-else>
                          <b-button v-if="Number(imagem.odontodocs) === 1" size="sm" variant="flat-danger"
                            class="btn-icon float-right" @click="excluirImagem(index, imagem.id, row.item.id)">
                            <feather-icon icon="Trash2Icon" class="text-danger" />
                          </b-button>
                          <b-button variant="flat"
                            :href="imagem.url ? `https://smartprotese.s3.sa-east-1.amazonaws.com/smartprotese/${imagem.url}` : '' "
                            target="_blank">
                            <feather-icon icon="FileIcon" size="70"></feather-icon>
                            <br>
                            <span>{{ imagem.nome.substr(0, 13) }}</span>
                            <br>
                            <span class="text-bold text-uppercase text-primary"><strong>{{ imagem.nome.substr(-3)
                                }}</strong></span>
                          </b-button>
                        </b-col>
                      </template>
                    </b-row>
                  </b-container>
                </b-row>
              </b-card>

            </template>

            <template #cell(opcoes)="data">

              <b-button class="btn-icon" variant="flat-secondary" style="padding: 2px" @click="data.toggleDetails">
                <feather-icon v-model="data.detailsShowing"
                  :class=" data.detailsShowing ? 'text-primary' : 'text-default' " icon="EyeIcon" size="16" />
              </b-button>

              <b-button class="btn-icon" variant="flat-secondary" style="padding: 2px" @click="addServicoOs(data.item)">
                <feather-icon class="text-default" icon="PlusIcon" size="16" />
              </b-button>
              <!-- MENSAGENS -> CHAT-->
              <b-button class="btn-icon" variant="flat-primary" style="padding: 2px"
                :disabled="data.item.situacao == ('Pedido não Enviado')" @click="modalChat(data.item)">
                <feather-icon icon="MessageCircleIcon" size="16" />
              </b-button>

              <!-- IMPRIMIR -->
              <b-button class="btn-icon" variant="flat-secondary" style="padding: 2px" @click="imprimirOs(data.item)">
                <feather-icon icon="PrinterIcon" size="16" />
              </b-button>

              <!-- EXCLUIR-->
              <b-button class="btn-icon" variant="flat-danger"
                :disabled="data.item.num_os || data.item.situacao === 'Cancelado' ? true : false" style="padding: 2px"
                @click="excluirPedido(data.item)">
                <feather-icon icon="Trash2Icon" size="16" />
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <ImprimirRelatorio :pedidos="itensFiltrados" :mes="filtros.mes" :ano="filtros.ano" :tipo="filtros.tipo"
      :sort="this.sort" :counter="this.counter" v-show="false" />
    <ImprimirPedidoOs :pedido="pedidoOs" :mes="filtros.mes" :ano="filtros.ano" v-show="false" />
    <modal-slides :imagens="imagensVerModal" :index="imagensIndex" @mudouIndex="imagensIndex = $event"></modal-slides>
    <ModalEtapas :item="itemEtapaEditar" :etapas-editar="etapasEditar" />
    <ModalAdicionarArquivosOs :pedido-id="osFichaId" :laboratorios="laboratorios" @buscaEntradas="buscaEntradas" />

  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCol,
  BCollapse,
  BContainer,
  BDropdown,
  BDropdownDivider,
  BDropdownForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  VBPopover,
  VBToggle
} from 'bootstrap-vue'
import {mapGetters, mapState} from 'vuex'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import filtroMeses from '@/services/filtroMeses'
import moment from 'moment'
import situacaoOpcoes from '@/services/situacaoOpcoes'
import ModalSlides from "@/views/pedidos/ModalSlides";
import ImprimirRelatorio from "@/views/pedidos/ImprimirRelatorio";
import ImprimirPedidoOs from "@/views/pedidos/ImprimirPedidoOs";
import ModalEtapas from "@/views/pedidos/ModalEtapas.vue";
import ModalAdicionarArquivosOs from "@/views/pedidos/ModalAdicionarArquivosOs.vue";
import traducaoSituacao from "@/services/traducaoSituacao";
import traducaoOpcoesDatas from "@/services/traducaoOpcoesDatas";
import formataData from '@/mixins/formataData';
import formataMoeda from '@/mixins/formataMoeda';

export default {

  mixins: [formataData, formataMoeda],

  components: {
    ModalAdicionarArquivosOs,
    BTable,
    BFormFile,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BCardBody,
    BDropdown,
    BDropdownForm,
    BDropdownDivider,
    BSpinner,
    BImg,
    BContainer,
    vSelect,
    ModalSlides,
    ImprimirRelatorio,
    ImprimirPedidoOs,
    ModalEtapas,
    'b-collapse': BCollapse

  },

  directives: {
    Ripple,
    'b-popover': VBPopover,
    'b-toggle': VBToggle
  },

  data() {
    return {
      podeLimparSelectConveniados: true,
      itemEtapaEditar: [],
      etapasEditar: [],
      pedidoOs: '',
      imagensVerModal: [],
      imagensIndex: 0,
      filtros: {
        situacao: [],
        cliente_id: '',
        conveniado_id: '',
        search: '',
        mes: this.$getDate.mesAtual(),
        ano: this.$getDate.anoAtual(),
        tipo: 'data_lancamento',
        laboratorioSelecionado: 0,
        etapas: false,
      },
      perPage: null,
      pageOptions: [3, 5, 10, 15, 20, 50, 100, 200],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: ['num_os', 'servico_nome', 'paciente'],
      boxTwo: '',
      ...situacaoOpcoes,
      ...filtroMeses,
      calendarioSemanal: {},
      calendarioCountIndex: 0,
      files: [],
      barUpload: 0,
      counter: 0,
      sort: '',
      osFichaId: 0
    }
  },
  computed: {

    situacaoOptionsTranslated() {
      return this.situacaoOpcoesFiltros.map(opcao => ({
        ...opcao,
        value: traducaoSituacao.traduzSituacao(opcao.value)
      }));
    },

    
    ...mapState({
      pedidos: state => state.pedidos.pedidos,
      produtos: state => state.pedidos.produtos,
      conveniados: state => state.pedidos.conveniados,
      laboratorios: state => state.laboratorios.laboSelecionado,
      config: state => state.laboratorios.configuracaoLaboSelecionado,
      imagens: state => state.pedidos.imagens,
      conversas: state => state.chat.listaServicosChat,
      etapas: state => state.pedidos.entradasEtapas,
      user: state => state.user.user
    }),
    ...mapGetters('laboratorios', ['selecionado', 'nomeLaboSelecionado']),

    filteredConveniados() {

      this.podeLimparSelectConveniados = true

      if(this.user.conveniado_id > 0) {

        this.filtros.conveniado_id = this.user.conveniado_id
        this.podeLimparSelectConveniados = false
        return this.conveniados.filter(conveniado => conveniado.id === this.user.conveniado_id);

      }

      return this.conveniados;
    },

    anos() {
      return this.$getDate.getAnoFiltros()
    },

    fields() {
      let nome = this.$t('Entrada')

      if (this.filtros.tipo === 'data_entregue') {
        nome = this.$t('Entregue')
      } else if (this.filtros.tipo === 'data_prazo') {
        nome = this.$t('Prazo')
      }

      return [
        { key: 'num_os', label: this.$t('os'), sortable: true },
        { key: this.filtros.tipo, label: nome, sortable: true },
        { key: 'quantidade', label: this.$t('qtd') },
        { key: 'servico_nome', label: this.$t('Servico'), sortable: true },
        { key: 'conveniado_nome', label: this.$t('Dentista'), sortable: true },
        { key: 'paciente', label: this.$t('Paciente'), sortable: true },
        { key: this.config.odonto_etapas ? this.$t('etapa') : '', label: this.$t('Etapas'), sortable: false},
        { key: 'situacao', label: this.$t('Situacao'), sortable: true },
        { key: 'opcoes', label: this.$t('Opcoes'), sortable: false, thStyle: { width: "180px"} },
      ]
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({ text: f.label, value: f.key }))
    },

    itensFiltrados() {
      let filtros = this.pedidos

      filtros = filtros.filter(v => (
          this.filtros.situacao == 'Todos'
          || this.filtros.situacao == ''
          || this.filtros.situacao.includes(v.situacao)
      ))

      if (this.filtros.conveniado_id > 0) {
        filtros = filtros.filter(v => v.conveniado_id == this.filtros.conveniado_id)
      }

      const result = []

      filtros.forEach(res => {
        let etapa = ''
        let nome_etapa = ''
        let cor_etapa = 'danger'

        const data_prazo = this.agrupaDataHora(res.data_prazo, res.hora_prazo)

        if (res.produto_id === null && res.cat_entrega_id === null) {

          if (this.etapas[res.items_id] && this.etapas[res.items_id] !== undefined) {
            etapa = this.etapas[res.items_id].find(f => f.data_finalizado == null)

            // verifica se etapa não está finalizada
            if (etapa !== undefined) {
              nome_etapa = etapa.nome_etapa

              if (etapa.data_inicio != null) {
                // ja iniciou a etapa
                nome_etapa = etapa.nome_etapa
                cor_etapa = 'light-success'
                res.funcionario_id = etapa.funcionario_id
              }
            } else {
              nome_etapa = 'Concluída'
              cor_etapa = 'light-primary'
            }
          }


          let botaoEnviarPedido = res.situacao === 'Pedido não Enviado' ? true : false
          result.push({
            ...res,
            botaoEnviarPedido,
            data_prazo,
            submit: false,
            etapa: nome_etapa,
            cor_etapa,
          })
        }
      })

      return result
    },

  },
  async created() {
    await this.buscaEntradas()
    if(this.selecionado) {
      this.$store.dispatch('pedidos/listaConveniados', this.selecionado)

      if(!this.$store.state.chat.listaServicosChat.length) {
        this.$store.dispatch('chat/listaServicosChat', this.selecionado)
      }

    }
  },



  watch: {
    laboratorios(newValue, oldvalue) {
      this.filtros.conveniado_id = ''
      this.$store.dispatch('pedidos/listaConveniados', this.selecionado)
      this.$store.dispatch('chat/listaServicosChat', this.selecionado)
      this.buscaEntradas()
    },
    sortBy(){
      this.sortChange()
      this.sort = this.sortBy
    }
  },
  methods: {

    abreModalAddArquivos(pedidoOs) {
      this.osFichaId = pedidoOs
      this.$bvModal.show('modalAddArquivos')
    },

    editarEtapa(data) {
      this.itemEtapaEditar = data
      this.etapasEditar = this.etapas[data.item.items_id]
      this.$bvModal.show('editarEtapas')
    },

    addServicoOs(servico) {
      this.$router.push({ name: 'pedidos_novo', params: {id: servico.id}})
    },

    sortChange() {

      this.counter = 0

    },

    sortChanged() {
      if (this.sort == this.sortBy) {
        this.counter += 1
      }
    },

    imprimirRelatorio() {
      this.$htmlToPaper('printMe', {
        name: 'Relatório Pedidos',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
          'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
          'https://unpkg.com/kidlat-css/css/kidlat.css'
        ]
      })
    },

    modalChat(serv) {
      let existeItem = undefined
      this.$store.commit('chat/STORE_LISTA_FILTRADA', [] )
      let item = {
        data_lancamento: moment(Date()).format('YYYY-MM-DD H:mm:ss'),
        nova_mensagem: false,
        num_os: serv.num_os,
        os_item_id: serv.items_id,
        paciente: serv.paciente,
        servico_nome: serv.servico_nome,
        tem_mensagem: false,
        id_company: this.laboratorios
      }

      if(this.conversas.length) {
        existeItem = this.conversas.find( v => v.os_item_id === serv.items_id)
      }

      if(existeItem == undefined || !this.conversas.length) {
        this.$store.commit('chat/ADD_LISTA_SERVICOS', item )
      }

      this.$store.commit('chat/STORE_SELECIONADO', serv.items_id)
      this.$store.commit('chat/STORE_ARRAY_SELECIONADO', item)

      let payload = {
        id_company: this.laboratorios,
        os_item_id: serv.items_id,
      }
      this.$store.dispatch('chat/listaMensagens', payload)
      this.$bvModal.show('chat')
    },

    buscaEntradas() {
      this.filtros.etapas = this.config.odonto_etapas
      this.filtros.laboratorioSelecionado = this.selecionado

      if(this.selecionado) {
        this.$store.dispatch('pedidos/listaPedidos', this.filtros)

      }

    },

    enviarPedido(pedido) {

      pedido.submit = true
      this.$store.commit('pedidos/UPDATE_PEDIDOS', pedido)

      const payload = {
        servico: pedido.servico_nome,
        paciente: pedido.paciente,
        id: pedido.id,
        cliente: this.nomeLaboSelecionado
      }
      this.$http.post('odontodocs/pedidos/enviar/'+this.laboratorios, payload).then( (res) => {
        pedido.situacao = "Pedido"
        this.$store.commit('pedidos/UPDATE_PEDIDOS', pedido)
      }).catch(error => {
        pedido.submit = false
        this.$store.commit('pedidos/UPDATE_PEDIDOS', pedido)
      })

    },

    imprimirOs(item) {

      this.$http.get('odontodocs/pedidos/imprimir/'+item.id).then( (res) => {
        this.pedidoOs = res.data

        if (this.pedidoOs !== '') {
          setTimeout(() => {
            this.$htmlToPaper('pedidoImprimir', {
              name: 'Relatório Pedidos',
              specs: [
                'fullscreen=yes',
                'titlebar=yes',
                'scrollbars=yes'
              ],
              styles: [
                'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
                'https://unpkg.com/kidlat-css/css/kidlat.css'
              ]
            })
          }, 500)
        }

      })

    },

    excluirPedido(data) {

      this.$swal({
        text: this.$t('Deseja_cancelar_este_Pedido_nao_podera_ser_revertido'),
        showCancelButton: true,
        confirmButtonText: this.$t('Sim'),
        cancelButtonText: this.$t('Nao'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,

      }).then(result => {

        if (result.value) {

          this.$http.post(`odontodocs/pedidos/excluir/${data.id}/${this.laboratorios}`).then( () => {

            data.situacao = 'Cancelado'
            this.$store.commit('pedidos/UPDATE_PEDIDOS', data)

          }).catch(error => {

            this.$swal({
              title: 'Erro!',
              text: this.$t('Nao_foi_possivel_excluir_o_Pedido_entre_em_contato_com_o_Laboratorio'),
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })

          })
        }
      })
    },

    excluirImagem(index, id, os_ficha_id) {

      this.$swal({
        text: "Deseja excluir esta imagem?",
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          let payload = {
            index: index,
            id: os_ficha_id
          }
          this.$http.post('odontodocs/pedidos/upload/excluir/'+id).then( (res) => {
          }).catch(error => {
            console.log('tem erro', error)
          })
          this.$store.commit('pedidos/DELETE_IMAGENS', payload)
        }
      })
    },

    agrupaDataHora(data, hora) {
      if (!data) return

      if (!hora) {
        hora = ''
      }

      const d = data.split('-')
      return `${d[2]}/${d[1]}/${d[0]} ${hora.substring(0, 5)}`
    },

    abreModalImagens(imagens, index) {
      this.imagensVerModal = imagens.filter( v => v.tipo === 1)
      this.imagensIndex = index
      this.$bvModal.show('imagemSlide')
    },


  },

  filters: {

    // formataMoeda(valor) {
    //   if (!valor) return '0,00'

    //   return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    // },

    formataDesconto(valor, tipo_desc) {
      let tipo = '%'
      if (tipo_desc == 1) {
        tipo = 'R$'
      }

      if (!valor) return '0,00'

      return `${tipo} ${valor}`
    },
    // converteData(data) {
    //   if (!data) return

    //   const d = data.split('-')
    //   return `${d[2]}/${d[1]}/${d[0]}`
    // },

    // converteDataHora(data) {
    //   if (!data) return

    //   return moment(data).format('DD/MM/YYYY H:mm')
    // },

    converteDataSetHora(data, hora) {
      if (!data) return

      if (!hora) {
        hora = ''
      }

      const d = data.split('-')
      return `${d[2]}/${d[1]}/${d[0]} ${hora}`
    },

    traduzSituacao(situacao) {
      return traducaoSituacao.traduzSituacao(situacao);
    },

    traduzOpcoesDatas(opcoes) {
      return traducaoOpcoesDatas.traduzOpcoesDatas(opcoes);
    }

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>

.bordaCardSemana {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.cardSemanas {
  position: absolute;
  top: 28px;
}
</style>
